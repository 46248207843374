import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './dropdown.module.scss';

const cx = classNames.bind(styles);

const DropDownItem = ({
  children,
  className,
  hideOnSelection,
  onClick
}) => {

  const handleClick = (element) => {
    if (onClick) {
      onClick(element);
    }
    if (hideOnSelection) {
      hideOnSelection();
    }
  };

  const dropDownItemClasses = cx('drop-down__hover-effect', className);

  return (
    <span
      role="button"
      tabIndex={0}
      className={dropDownItemClasses}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {children}
    </span>
  );
};

DropDownItem.displayName = 'DropDownItem';

DropDownItem.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  hideOnSelection: PropTypes.func,
  onClick: PropTypes.func
};

DropDownItem.defaultProps = {
  children: null,
  className: null,
  hideOnSelection: null,
  onClick: null
};

export { DropDownItem };
