import React, { useEffect, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames/bind';
import { AccordionContext } from './AccordionContext';
import styles from './accordion.module.scss';

const cx = classNames.bind(styles);

const Accordion = ({
  children,
  className,
  controlled,
  icon,
  expanded,
  onChange
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const iconMerged = { ...Accordion.defaultProps.icon, ...icon };

  const accordionClasses = cx('accordion', className);

  const contextValue = {
    expanded: isExpanded,
    setExpanded: controlled ? () => {} : setIsExpanded,
    icon: iconMerged,
    onChange
  };
  return (
    <AccordionContext.Provider value={contextValue}>
      <div className={accordionClasses}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  /** Children */
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  /** Classname */
  className: string,
  /** state is handled by parent, not internally */
  controlled: bool,
  /** Expanded by Default */
  expanded: bool,
  /** Icon with properties */
  icon: shape({
    position: oneOf(['left', 'right']),
    size: oneOf(['sm', 'md']),
    type: oneOf(['caret'])
  }),
  /* onChange */
  onChange: func
};

Accordion.defaultProps = {
  className: null,
  controlled: false,
  expanded: false,
  icon: {
    position: 'right',
    size: 'md',
    type: 'caret'
  },
  onChange: () => {}
};

Accordion.displayName = 'Accordion';

export { Accordion };
