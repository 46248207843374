import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../collections/Grid';
import { RadioGroup } from './RadioGroup';
import styles from './radio.module.scss';

const Radio = ({
  children,
  checked,
  disabled,
  formName,
  id,
  onChange,
  value,
  className,
  forwardedRef,
  label: labelText,
  padding
}) => {

  const ElementType = padding ? Col : 'div';

  return (
    <ElementType className={className}>
      <input
        className={styles['radio-btn__input']}
        type="radio"
        disabled={disabled}
        defaultChecked={checked}
        ref={forwardedRef}
        id={id}
        name={formName}
        onChange={onChange}
        value={value}
      />
      { /* eslint-disable-next-line */ }
      <label className={styles['radio-btn__label']} htmlFor={id}>
        <span className={styles['radio-btn']} />
        {labelText || children}
      </label>
    </ElementType>
  );
};

Radio.displayName = 'Radio';

Radio.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render checkbox as checked */
  checked: PropTypes.bool,
  /** HTML attribute, if true then make checkbox grey and unclickable */
  disabled: PropTypes.bool,
  /** Groups of inputs/labels require matching name, value does not matter */
  formName: PropTypes.string,
  /** Ref forwarded from parent */
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    /* eslint-disable-next-line */
    PropTypes.shape({ current: PropTypes.any })
  ]),
  /** ID is required for interacting if multiple checkboxes are on page at once */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired,
  /** Form value, useful for wrapper implementation */
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.bool
};

Radio.defaultProps = {
  children: null,
  checked: false,
  disabled: false,
  formName: '',
  forwardedRef: null,
  className: null,
  label: null,
  padding: false
};

// Deprecated Contract
//
// The following will be removed in the future

const DeprecatedRadioGroup = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RadioGroup {...props} />
  );
};

Radio.Group = DeprecatedRadioGroup;

export { Radio };
