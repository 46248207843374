import React from 'react';
import { List } from './List';
import { ListItem } from './ListItem';

// Deprecated Contract
//
// The following will be removed in the future

const OrderedList = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <List type="ordered" {...props} />
  );
};

OrderedList.displayName = 'OrderedList';

const RoundList = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <List type="round" {...props} />
  );
};

RoundList.displayName = 'RoundList';

const UnstyledList = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <List type="plain" {...props} />
  );
};

UnstyledList.displayName = 'UnstyledList';

const InlineList = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <List type="inline" {...props} />
  );
};

InlineList.displayName = 'InlineList';

const LinkItem = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItem link {...props} />
  );
};

LinkItem.displayName = 'LinkItem';

export {
  OrderedList,
  RoundList,
  UnstyledList,
  InlineList,
  LinkItem
};
