import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { addLinkClasses } from './helper';
import styles from './list.module.scss';

const ListItem = ({ children, className, link }) => {
  const classes = classNames(className);
  return (
    <li className={classes}>
      {link && (addLinkClasses(children, styles.list__link))}
      {!link && (children)}
    </li>
  );
};

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string,
  link: PropTypes.bool
};

ListItem.defaultProps = {
  className: null,
  link: false
};

export { ListItem };
