import React from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../../utils';

const RadioGroup = ({
  children,
  className
}) => {

  const formName = UUID();

  const radioButtons = React.Children.map(children, (radioButton) => React.cloneElement(radioButton, {
    formName
  }));

  return (
    <form className={className}>
      {radioButtons}
    </form>
  );
};

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string
};

RadioGroup.defaultProps = {
  className: null
};

export { RadioGroup };
