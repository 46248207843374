import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../collections/Grid';
import styles from './toggle.module.scss';

const Toggle = ({
  children,
  checked,
  className,
  disabled,
  forwardedRef,
  id,
  onChange,
  label: labelText,
  padding
}) => {

  const ElementType = padding ? Col : 'div';

  return (
    <ElementType className={className}>
      <div className={styles['toggle-btn']}>
        <input
          className={styles['toggle-btn__input']}
          checked={checked}
          disabled={disabled}
          id={id}
          onChange={onChange}
          ref={forwardedRef}
          type="checkbox"
        />
        { /* eslint-disable-next-line */}
        <label className={styles['toggle-btn__label']} htmlFor={id}>
          <span className={styles['toggle-btn__knob']} />
        </label>
        <span className={styles['toggle-btn__name']}>
          {labelText || children}
        </span>
      </div>
    </ElementType>
  );
};

Toggle.displayName = 'Toggle';

Toggle.propTypes = {
  /** Optional text to right of toggle */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render toggle as checked */
  checked: PropTypes.bool,
  className: PropTypes.string,
  /** HTML attribute, if true then make toggle grey and unclickable */
  disabled: PropTypes.bool,
  /** Ref forwarded from parent */
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    /* eslint-disable-next-line */
    PropTypes.shape({ current: PropTypes.any })
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  padding: PropTypes.bool
};

Toggle.defaultProps = {
  children: null,
  checked: false,
  className: null,
  disabled: false,
  forwardedRef: null,
  label: null,
  padding: true
};

export { Toggle };
