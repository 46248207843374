import React, { useContext } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames/bind';
import { Row } from '../../../collections/Grid';
import { AccordionContext } from '../AccordionContext';
import styles from './accordion-body.module.scss';

const cx = classNames.bind(styles);

const AccordionBody = ({ children, className }) => {
  const { expanded } = useContext(AccordionContext);

  const bodyClasses = cx({
    'accordion-body': true,
    'accordion-body--expanded': expanded,
  });

  const wrapperClasses = classNames(className, cx('accordion-body__wrapper'));

  return (
    <div className={bodyClasses}>
      <Row className={wrapperClasses}>{children}</Row>
    </div>
  );
};

AccordionBody.propTypes = {
  /** Children */
  children: node.isRequired,
  /** ClassName */
  className: string
};

AccordionBody.defaultProps = {
  className: ''
};

AccordionBody.displayName = 'AccordionBody';

export { AccordionBody };
