import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({
  children,
  className
}) => {
  return (
    <tbody className={className}>{children}</tbody>
  );
};

TableBody.displayName = 'TableBody';

TableBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

TableBody.defaultProps = {
  children: null,
  className: null
};

export { TableBody };