import React from 'react';
import {
  bool, node, oneOf, shape, string
} from 'prop-types';
import classNames from 'classnames/bind';
import styles from './card.module.scss';

const cx = classNames.bind(styles);

const CardFootnote = ({
  action,
  alignment,
  className,
  footnote,
  link,
  noPadding
}) => {

  const cardFootnoteClasses = cx('card-footnote', {
    'card-footnote--no-padding': noPadding,
    'card-footnote--right': alignment && alignment === 'right' && !(link && footnote),
    'card-footnote--center': alignment && alignment === 'center' && !(link && footnote),
    'card-footnote--space-between': link && footnote,
  }, className);

  const cardActionClasses = cx({
    'card--link': action && action.href
  }, action?.className);

  const ElementType = action && action.href ? 'a' : 'div';

  const renderDeprecatedCardFootnote = () => {
    return (
      <>
        { link && !footnote
      && <a className={styles['card--link']} href={link.href}>{ link.label }</a> }
        { !link && footnote && <div> { footnote }</div> }
        { link && footnote && ([
          <span key="footnote"> { footnote }</span>,
          <a
            key="footnoteLink"
            className={styles['card--link']}
            href={link.href}
          >{ link.label }
          </a>
        ]) }
      </>
    );
  };

  return (
    <div className={cardFootnoteClasses}>
      { !action
        ? renderDeprecatedCardFootnote()
        : (
          <ElementType href={action && action.href} className={cardActionClasses}>
            { action?.content }
          </ElementType>
        )}
    </div>
  );
};

CardFootnote.displayName = 'CardFootnote';

CardFootnote.propTypes = {
  action: shape({
    content: node,
    className: string,
    href: string,
  }),
  alignment: oneOf(['left', 'center', 'right']),
  className: string,
  footnote: string,
  link: shape({
    label: string,
    href: string
  }),
  noPadding: bool
};

CardFootnote.defaultProps = {
  action: null,
  alignment: 'left',
  className: null,
  footnote: null,
  link: null,
  noPadding: false
};

export { CardFootnote };
