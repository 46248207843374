import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup } from './CheckboxGroup';
import { Col } from '../../collections/Grid';
import styles from './checkbox.module.scss';

const Checkbox = ({
  children,
  checked,
  className,
  disabled,
  formName,
  id,
  onChange,
  label: labelText,
  value,
  padding
}) => {

  const ElementType = padding ? Col : 'div';

  let optionalProps = {};

  if (typeof checked !== 'undefined') {
    optionalProps.checked = checked;
  } else {
    optionalProps.defaultChecked = checked;
  }

  return (
    <ElementType className={className}>
      <div className={styles['checkbox-btn']}>
        <input
          className={styles['checkbox-btn__input']}
          type="checkbox"
          disabled={disabled}
          id={id}
          name={formName}
          onChange={onChange}
          value={value}
          {...optionalProps} // eslint-disable-line
        />
        { /* eslint-disable-next-line */ }
        <label className={styles[`checkbox-btn__label`]} htmlFor={id}>
          {labelText || children}
        </label>
      </div>
    </ElementType>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  /** Optional text to right of checkbox */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** HTML attribute, if true then render checkbox as checked */
  checked: PropTypes.bool,
  className: PropTypes.string,
  /** HTML attribute, if true then make checkbox grey and unclickable */
  disabled: PropTypes.bool,
  /** Groups of inputs/labels require matching name, value does not matter */
  formName: PropTypes.string,
  /** ID is required for interacting if multiple checkboxes are on page at once */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string,
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired,
  /** Form value, useful for wrapper implementation */
  value: PropTypes.string,
  padding: PropTypes.bool
};

Checkbox.defaultProps = {
  children: null,
  checked: false,
  className: null,
  disabled: false,
  formName: '',
  label: null,
  value: '',
  padding: true
};

const DeprecatedCheckboxGroup = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CheckboxGroup {...props} />
  );
};

Checkbox.Group = DeprecatedCheckboxGroup;

export { Checkbox };
