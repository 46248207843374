import React, { useContext } from 'react';
import {
  bool,
  func,
  node,
  oneOf,
  string
} from 'prop-types';
import classNames from 'classnames/bind';
import { Image } from '../../../elements/Image';
import { AccordionContext } from '../AccordionContext';
import styles from './accordion-title.module.scss';

const cx = classNames.bind(styles);

const AccordionTitle = ({ children, className }) => {
  const {
    expanded,
    setExpanded,
    icon,
    onChange
  } = useContext(AccordionContext);
  const { position, size } = icon;

  const handleChange = () => {
    onChange();
    setExpanded(!expanded);
  };
  const CARET_SRC_SM = 'https://assets.thdstatic.com/images/v1/caret-brand-small.svg';
  const CARET_SRC_MD = 'https://assets.thdstatic.com/images/v1/caret-brand-large.svg';

  const accordionSummaryClasses = cx('accordion-title', className);

  const childClasses = cx('accordion-title__child', {
    'accordion-title__child--left': position === 'right',
    'accordion-title__child--right': position === 'left'
  });

  const iconClasses = cx('accordion-title__icon', {
    'accordion-title__icon--expanded': expanded,
    'accordion-title__icon--unexpanded': !expanded,
    'accordion-title__icon--left': position === 'left',
    'accordion-title__icon--right': position === 'right',
    'accordion-title__icon--small': size === 'sm'
  });

  const iconHeight = size === 'sm' ? '16' : '24';
  const iconSrc = size === 'sm' ? CARET_SRC_SM : CARET_SRC_MD;

  return (
    <div
      className={accordionSummaryClasses}
      onClick={() => handleChange()}
      role="button"
      tabIndex={0}
    >
      <Image
        alt="caret"
        className={iconClasses}
        height={iconHeight}
        src={iconSrc}
        width={iconHeight}
      />
      <div className={childClasses}>{children}</div>
    </div>
  );
};

AccordionTitle.propTypes = {
  /** Children */
  children: node.isRequired,
  /** ClassName */
  className: string
};

AccordionTitle.defaultProps = {
  className: ''
};

AccordionTitle.displayName = 'AccordionTitle';

export { AccordionTitle };
