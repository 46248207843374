import React from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../../utils';

const CheckboxGroup = ({
  children,
  className,
  radio
}) => {

  const checkboxes = React.Children.map(children, (checkbox) => React.cloneElement(checkbox, {
    radio,
    name: checkbox.props?.name || UUID()
  }));

  return (
    <div className={className}>
      {checkboxes}
    </div>
  );
};

CheckboxGroup.displayName = 'CheckboxGroup';

CheckboxGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string,
  radio: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  className: null,
  radio: false
};

export { CheckboxGroup };
