import React from 'react';
import PropTypes from 'prop-types';
import styles from './dropdown.module.scss';

const DropDownSearch = ({ onChange }) => {
  return (
    <span className={styles['dropdown-search']}>
      <input
        className={styles['dropdown-search__field']}
        id="searchItems"
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder="Search"
        type="text"
      />
    </span>
  );
};

DropDownSearch.displayName = 'DropDownSearch';

DropDownSearch.propTypes = {
  /** Action to perform for checkbox click */
  onChange: PropTypes.func.isRequired
};

export { DropDownSearch };
