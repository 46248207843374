import React from 'react';
import PropTypes from 'prop-types';
import styles from './select.module.scss';

const Select = ({
  header,
  selected,
  options,
  onChange
}) => {

  const mapOptions = (choices) => {
    return choices.map((choice) => <option key={choice.value} value={choice.value}>{choice.name}</option>);
  };

  return (
    <>
      {header && <span className={styles.select__label}>{header}</span>}
      <form>
        <span className={styles.select__title}>
          <select
            className={styles.select__container}
            onChange={onChange}
            value={selected}
          >
            {mapOptions(options)}
          </select>
        </span>
      </form>
    </>
  );
};

Select.displayName = 'Select';

Select.propTypes = {
  /** Header text displayed above select drop down */
  header: PropTypes.string,
  /** Function which handles change in select drop down */
  onChange: PropTypes.func.isRequired,
  /** List of options that appear in drop down */
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  /** Selected option from the list */
  selected: PropTypes.string
};

Select.defaultProps = {
  header: null,
  selected: null
};

export { Select };
