/* eslint-disable max-len */
import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames';
import {
  A11y,
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './carousel.scss';

const SCREEN_SM = 640;
const SCREEN_MD = 768;
const SCREEN_LG = 1024;
const SCREEN_XL = 1280;
const PEEK_DEFAULT = 0.3;

const Carousel = ({
  autoplay,
  breakpoints,
  children,
  disableFocus,
  dynamicPagination,
  hideCarouselArrows,
  loop,
  navigation,
  onSlideChange,
  onSlideNextTransitionStart,
  onSlidePrevTransitionStart,
  onSwiper,
  pagination,
  peek,
  slidesPerGroup,
  slidesPerView
}) => {
  const calcSlidesPerView = (value) => {
    if (value === 'auto') {
      return 'auto';
    }
    return value + (peek ? PEEK_DEFAULT : 0);
  };
  const _breakpoints = {
    [SCREEN_SM]: {
      slidesPerView: breakpoints.sm ? calcSlidesPerView(breakpoints.sm.slidesPerView) : 0,
      slidesPerGroup: breakpoints.sm?.slidesPerGroup
    },
    [SCREEN_MD]: {
      slidesPerView: breakpoints.md ? calcSlidesPerView(breakpoints.md.slidesPerView) : 0,
      slidesPerGroup: breakpoints.md?.slidesPerGroup
    },
    [SCREEN_LG]: {
      slidesPerView: breakpoints.lg ? calcSlidesPerView(breakpoints.lg.slidesPerView) : 0,
      slidesPerGroup: breakpoints.lg?.slidesPerGroup
    },
    [SCREEN_XL]: {
      slidesPerView: breakpoints.xl ? calcSlidesPerView(breakpoints.xl.slidesPerView) : 0,
      slidesPerGroup: breakpoints.xl?.slidesPerGroup
    }
  };
  const swiperClasses = classNames({
    'swiper-dots-below': pagination,
    'hide-carousel-arrows': hideCarouselArrows
  });
  let _slidesPerGroup = slidesPerGroup;
  const windowWidth = global?.window?.innerWidth;
  if (windowWidth) {
    if (windowWidth >= SCREEN_XL) {
      _slidesPerGroup = _breakpoints[SCREEN_XL]?.slidesPerGroup;
    } else if (windowWidth >= SCREEN_LG) {
      _slidesPerGroup = _breakpoints[SCREEN_LG]?.slidesPerGroup;
    } else if (windowWidth >= SCREEN_MD) {
      _slidesPerGroup = _breakpoints[SCREEN_MD]?.slidesPerGroup;
    } else if (windowWidth >= SCREEN_SM) {
      _slidesPerGroup = _breakpoints[SCREEN_SM]?.slidesPerGroup;
    }
  }
  const resetFocus = (swiper) => {
    setTimeout(() => {
      // Remove tabindex from all slides
      swiper.el.querySelectorAll('.swiper-slide[tabindex="0"]').forEach((slide) => {
        slide.removeAttribute('tabindex');
      });
      // Remove tabindex from all buttons and anchors
      swiper.el.querySelectorAll('button, a').forEach((el) => {
        el.setAttribute('tabindex', '-1');
      });
      // Add back tabindex to visible slides and their buttons/anchors
      const visibleSlides = swiper.el.querySelectorAll('.swiper-slide-visible');
      visibleSlides.forEach((slide, slideIndex) => {
        if (!peek || (slideIndex + 1) !== visibleSlides.length) {
          slide.setAttribute('tabindex', '0');
          slide.querySelectorAll('button, a').forEach((el) => {
            el.setAttribute('tabindex', '0');
          });
        }
      });
    }, 2000);
  };

  return (
    <Swiper
      modules={[A11y, Autoplay, Mousewheel, Pagination, Navigation]}
      a11y={{
        prevSlideMessage: `Go to slide ${children.length}`,
        nextSlideMessage: `Go to slide ${_slidesPerGroup + 1}`
      }}
      autoplay={autoplay ? {
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        delay: 5000
      } : false}
      breakpoints={_breakpoints}
      className={swiperClasses}
      loop={loop}
      mousewheel={{
        forceToAxis: true
      }}
      navigation={navigation}
      onSwiper={(swiper) => {
        // Moves next button and pagination after swiper wrapper to correct tab order
        swiper.el.append(swiper.navigation.nextEl);
        if (pagination) {
          swiper.el.append(swiper.el.querySelector('.swiper-pagination'));
        }
        resetFocus(swiper);
        onSwiper();
      }}
      onSlideChange={(swiper) => {
        const prevButton = swiper.navigation.prevEl;
        const nextButton = swiper.navigation.nextEl;

        // Updates for screen readers which slide is next/prev
        if (prevButton && nextButton) {
          const prevSlideNumber = swiper.realIndex + 1 - _slidesPerGroup;
          const nextSlideNumber = swiper.realIndex + 1 + _slidesPerGroup;
          prevButton.setAttribute('aria-label', `Go to slide ${prevSlideNumber > 0 ? prevSlideNumber : prevSlideNumber + children.length}`);
          nextButton.setAttribute('aria-label', `Go to slide ${nextSlideNumber <= children.length ? nextSlideNumber : nextSlideNumber - children.length}`);
        }

        const scrollPosition = global?.window?.pageYOffset;

        resetFocus(swiper);
        setTimeout(() => {
          const activeSlide = swiper.el.querySelector('.swiper-slide-active');
          if (activeSlide && scrollPosition === global?.window?.pageYOffset && (!swiper.autoplay.running || swiper.autoplay.paused) && !disableFocus) {
            activeSlide.focus();
          }
        }, 2000);
        onSlideChange();
      }}
      onSlideNextTransitionStart={onSlideNextTransitionStart}
      onSlidePrevTransitionStart={onSlidePrevTransitionStart}
      pagination={pagination ? { clickable: true, dynamicBullets: dynamicPagination } : false}
      slidesPerGroup={slidesPerGroup}
      slidesPerView={slidesPerView === 'auto' ? 'auto' : slidesPerView + (peek ? PEEK_DEFAULT : 0)}
      spaceBetween={10}
      watchSlidesProgress
    >
      {children.map((slide, index) => {
        return (
          <SwiperSlide key={`slide-${index}`}>
            { slide }
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  autoplay: bool,
  breakpoints: shape(),
  children: arrayOf(node).isRequired,
  disableFocus: bool,
  dynamicPagination: bool,
  hideCarouselArrows: bool,
  loop: bool,
  navigation: bool,
  onSlideChange: func,
  onSlideNextTransitionStart: func,
  onSlidePrevTransitionStart: func,
  onSwiper: func,
  pagination: bool,
  peek: bool,
  slidesPerGroup: number,
  slidesPerView: oneOfType([
    number,
    string
  ])
};

const NOOP = () => {};
Carousel.defaultProps = {
  autoplay: false,
  breakpoints: {
    sm: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    md: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    lg: {
      slidesPerView: 5,
      slidesPerGroup: 5
    },
    xl: {
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  },
  disableFocus: false,
  dynamicPagination: false,
  hideCarouselArrows: false,
  loop: false,
  navigation: true,
  onSlideChange: NOOP,
  onSlideNextTransitionStart: NOOP,
  onSlidePrevTransitionStart: NOOP,
  onSwiper: NOOP,
  pagination: false,
  peek: true,
  slidesPerGroup: 1,
  slidesPerView: 2
};

export { Carousel };
