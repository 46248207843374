import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({
  name,
  children,
  className
}) => {

  return (
    <tr name={name} className={className}>{children}</tr>
  );
};

TableRow.displayName = 'TableRow';

TableRow.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

TableRow.defaultProps = {
  name: null,
  className: null,
  children: null,
};

export { TableRow };