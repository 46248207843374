import React, { useState } from 'react';
import {
  arrayOf, bool, node, oneOfType, string
} from 'prop-types';
import classNames from 'classnames/bind';
import { DropDownSearch } from './DropDownSearch';
import { DropDownItem } from './DropDownItem';
import styles from './dropdown.module.scss';

const cx = classNames.bind(styles);

const DropDown = ({
  children,
  className,
  contentClass,
  titleClass,
  expandOnClick,
  hideOnSelection,
  header,
  enableScrolling
}) => {

  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);

  const onSelectionChange = () => {
    setHide(true);
  };

  const dropdownItems = ({ items }) => {
    return items.map((item) => {
      return {
        ...item,
        props: {
          ...item.props,
          hideOnSelection: hideOnSelection ? onSelectionChange : null
        }
      };
    });
  };

  const showList = () => {
    if (!expandOnClick) {
      setHide(false);
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const close = () => {
    setOpen(false);
  };

  const isArray = Array.isArray(children);
  const type = isArray ? children[0].type : children.type;
  const isSearch = type === DropDownSearch;
  const kids = isArray ? children : [children].filter((child) => child);

  const wrapperClasses = cx('drop-down__content-wrapper', {
    'drop-down__content-wrapper--hidden': !expandOnClick && hide,
    'drop-down__content-wrapper--scrollable': enableScrolling,
  }, contentClass, className);

  const headerClasses = cx({
    'drop-down__header': !expandOnClick,
    'drop-down__header--touch': expandOnClick,
    'drop-down__header--open': expandOnClick && open
  });

  const titleClasses = cx('drop-down__title', titleClass, className);

  return (
    <div
      className={headerClasses}
      onClick={toggleOpen}
      onMouseLeave={close}
      role="button"
      tabIndex={0}
    >
      <span className={titleClasses} onMouseEnter={showList}>{header}</span>
      <div className={wrapperClasses}>
        {isSearch
          ? children
          : dropdownItems({ items: kids })}

      </div>
    </div>
  );
};

DropDown.displayName = 'DropDown';

DropDown.propTypes = {
  /** Drop Down Items */
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  className: string,
  titleClass: string,
  contentClass: string,
  expandOnClick: bool,
  hideOnSelection: bool,
  /** Header */
  header: oneOfType([
    node,
    string
  ]),
  enableScrolling: bool
};

DropDown.defaultProps = {
  className: null,
  titleClass: null,
  contentClass: null,
  expandOnClick: false,
  hideOnSelection: false,
  header: null,
  enableScrolling: false
};

// Deprecated Contract
//
// The following will be removed in the future

const DeprecatedDropDownItem = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DropDownItem {...props} />
  );
};

DropDown.Item = DeprecatedDropDownItem;

const DeprecatedDropDownSearch = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DropDownSearch {...props} />
  );
};

DropDown.Search = DeprecatedDropDownSearch;

export { DropDown };
