import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDomPath } from '@thd-olt-functional/utils';
import { triggerEvent } from '../../event-bus';
import styles from './alert.module.scss';

const cx = classNames.bind(styles);

const Alert = ({
  children,
  dismiss,
  inline,
  keyTriggers,
  message,
  title,
  type,
  noClose
}) => {

  const alertClass = inline ? 'alert-inline' : 'alert';
  const showClose = (type === 'danger') ? (!noClose && !inline) : !inline;

  const keyPress = (event) => {
    const triggers = keyTriggers ? keyTriggers : [13, 32]; /* eslint-disable-line */
    if (triggers.indexOf(event.keyCode) > -1) {
      event.currentTarget.parentNode.remove();
    }
  };

  const onClickHandler = (event) => {
    dismiss();
    event.currentTarget.parentNode.remove();
  };

  const [path, ref] = useDomPath();

  useEffect(() => {
    if ((type === 'danger' || type === 'warning') && path?.length > 0) {
      triggerEvent('alert.ready', {
        path,
        inline,
        message,
        title,
        type
      });
    }
  }, [path?.length]);

  const alertClasses = cx(`${alertClass}`, [`${alertClass}--${type}`]);

  return (
    <>
      {
        (title || message || children) && (
          <div className={alertClasses} ref={ref}>
            <span className={styles[`${alertClass}__message`]}>
              {
                title
                && <span className={styles[`${alertClass}__title`]}>{title} </span>
              }
              {message}
              {children}
            </span>
            {
              showClose
              /* eslint-disable-next-line */
              && <i className={styles.alert__close} onKeyDown={keyPress} onClick={onClickHandler} role="button" tabIndex="0" />
            }
          </div>
        )
      }
    </>
  );
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /**
   * Handler for close icon click
   */
  dismiss: PropTypes.func,
  /** Display the alert inline */
  inline: PropTypes.bool,
  /** Do not Display Close icon. Only works with danger type */
  noClose: PropTypes.bool,
  /**
   * Accessibility Linter: Allow remove on focus via space or enter
   */
  keyTriggers: PropTypes.arrayOf(PropTypes.number),
  /** Alert Message */
  message: PropTypes.string,
  /** Alert Title (bold text) */
  title: PropTypes.string,
  /** Alert type. One of 'danger' | 'success' | 'information' | 'warning' | 'close' */
  type: PropTypes.oneOf(['danger', 'success', 'information', 'warning', 'close']),
};

Alert.defaultProps = {
  children: null,
  dismiss: () => {},
  inline: false,
  keyTriggers: [13, 32],
  message: null,
  title: null,
  type: 'danger',
  noClose: false
};

export { Alert };
