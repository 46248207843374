import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { addListItemClass } from './helper';
import styles from './list.module.scss';

const cx = classNames.bind(styles);

const List = ({
  children,
  className,
  type
}) => {

  if (!children) return null;
  if (children && children.length === 0) return null;

  const ElementType = type === 'ordered' ? 'ol' : 'ul';

  const listClasses = cx('list', {
    'list--type-square': type === 'square',
    'list--type-disc': type === 'round',
    'list--type-plain': (type === 'plain' || type === 'inline'),
  }, className);

  const listItemClasses = cx({
    list__item: type !== 'inline',
    'list__item--padding-none': type === 'plain',
    'list__item--type-inline': type === 'inline',
  });

  return (
    <ElementType className={listClasses}>
      {addListItemClass(children, listItemClasses)}
    </ElementType>
  );
};

List.displayName = 'List';

List.propTypes = {
  /** The list items */
  children: PropTypes.oneOfType([
    // List Items are modified to add classes so can't set specific type
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Additional class names */
  className: PropTypes.string,
  /** List type */
  type: PropTypes.oneOf(['square', 'round', 'plain', 'inline', 'ordered'])
};

List.defaultProps = {
  className: null,
  type: 'square'
};

export { List };
