import React from 'react';
import classNames from 'classnames';
import { UUID } from '../../utils';

export const addListItemClass = (children, listItemClass) => {
  const childrenList = children.length ? children : [children];

  return childrenList.filter((ch) => ch).map((child) => {
    const childClasses = child.props ? child.props.className : '';
    const classes = classNames(listItemClass, childClasses);
    return React.cloneElement(child, { key: UUID(), className: classes });
  });
};

export const addLinkClasses = (children, linkClass) => {
  const childrenList = children.length ? children : [children];

  return childrenList.map((child) => {
    if (child.type === 'a') {
      return React.cloneElement(child, { key: UUID(), className: linkClass });
    }
    return child;
  });
};