import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './table.module.scss';

export const addChildrenAndName = (children, name) => {
  return children.map((child, i) => {
    return {
      ...child,
      props: {
        ...child.props,
        key: child.props.wrapperKey || `${name}-${i}`,
        id: child.props.id || `${name}-${i}`,
        name
      }
    };
  });
};

const Table = ({
  title,
  children,
  name,
  className,
  hasTableStripes
}) => {

  const tableClasses = classNames(styles['table-container__table'], {
    [styles.stripedBackground]: hasTableStripes
  }, className);

  return (
    <div className={styles['table-container']}>
      {title ? (
        <div className={styles['table-container__title']}>
          <h2>{title}</h2>
        </div>
      ) : null}
      <table className={tableClasses}>
        {
          children
          && addChildrenAndName(
            children.length ? children : [children],
            name
          )
        }
      </table>
    </div>
  );
};

Table.displayName = 'Table';

Table.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  name: PropTypes.string,
  className: PropTypes.string,
  hasTableStripes: PropTypes.bool
};

Table.defaultProps = {
  title: null,
  name: null,
  className: null,
  hasTableStripes: false,
  children: null
};

export { Table };
