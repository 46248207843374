import React from 'react';
import { Toggle } from './Toggle';

const ToggleSwitch = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Toggle {...props} />
  );
};

ToggleSwitch.displayName = 'ToggleSwitch';

export { ToggleSwitch };
