import React from 'react';
import { TableCell } from './TableCell';

const TableColumn = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableCell {...props} />
  );
};

TableColumn.displayName = 'TableColumn';

const TableHeader = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableCell header {...props} />
  );
};

TableHeader.displayName = 'TableHeader';

export {
  TableColumn,
  TableHeader
};
