import React from 'react';
import PropTypes from 'prop-types';

const TableCell = ({
  children,
  className,
  header
}) => {
  const ElementType = header ? 'th' : 'td';
  return (
    <ElementType className={className}>{children}</ElementType>
  );
};

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  header: PropTypes.bool
};

TableCell.defaultProps = {
  children: null,
  className: null,
  header: false
};

export { TableCell };