import React from 'react';
import PropTypes from 'prop-types';

const TableHead = ({
  children,
  className
}) => {
  return (
    <thead className={className}>{children}</thead>
  );
};

TableHead.displayName = 'TableHead';

TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

TableHead.defaultProps = {
  children: null,
  className: null
};

export { TableHead };